import { http } from '@/utils/api/http.js';
// 模型

/**
 * 教师获取课程列表
 * @param {TeacherLessonPage} pageModel 页面请求数据
 * @param {number} startTime
 * @param {number} endTime
 * @returns {Promise}
 */
export function getPageCurriculumInfo(pageModel, startTime, endTime) {
  return http({
    url: '/master/curriculum/getPageCurriculumInfo',
    method: 'GET',
    data: pageModel.toSpecialMap(),
  });
}

/**
 * 修改课程时间
 * @param {Number} cId 课程id
 * @param {Number} updateCurriculumTime 修改的课程时间_开始时间
 * @returns {Promise}
 */
export function setCurriculumTime(cId, updateCurriculumTime) {
  return http({
    url: '/master/curriculum/setCurriculumTime',
    method: 'POST',
    data: {
      cId,
      updateCurriculumTime,
    },
  });
}

/**
 * 查看学生的课程详细
 * @param {Number} studentId 课程id
 * @param {Number} curriculumId 修改的课程时间_开始时间
 * @returns {Promise}
 */
export function getStudentCurriculumInfo(studentId, curriculumId) {
  return http({
    url: '/master/curriculum/getStudentCurriculumInfo',
    method: 'GET',
    data: {
      studentId,
      curriculumId,
    },
  });
}

/**
 * 查看课程
 * @param {Number} cId 课程id
 * @returns {Promise}
 */
export function getCurriculumInfo(cId) {
  return http({
    url: '/master/curriculum/getCurriculumInfo/' + cId,
    method: 'GET',
  });
}

/**
 * 审核学生作业
 * @param {Object} object 传入值
 * @param {Number} object.curriculumId 课程id
 * @param {Number} object.studentId 学生id
 * @param {Number} object.directoryId 作业目录id
 * @param {Number} object.audit 审核结果_1通过_3未通过
 * @returns {Promise}
 */
export function auditHomework({
  curriculumId,
  studentId,
  directoryId,
  audit,
}) {
  return http({
    url: '/master/curriculum/auditHomework/',
    method: 'POST',
    data: {
      curriculumId,
      studentId,
      directoryId,
      audit,
    },
  });
}

/**
 * 设置学生的回放权限
 * @param {Object} obj 传入值
 * @param {Number} obj.studentId 学生id
 * @param {Number} obj.curriculumId 课程id
 * @param {Number} obj.choose 设置：0关闭、1开启
 * @returns {Promise}
 */
export function setStudentPlayback({
  studentId,
  curriculumId,
  choose,
}) {
  return http({
    url: '/master/curriculum/setStudentPlayback',
    method: 'POST',
    data: {
      studentId,
      curriculumId,
      choose,
    },
  });
}

/**
 * 编辑 学生的本次课程 反馈
 * @param {Object} obj 传入值
 * @param {Number} obj.studentId 学生id
 * @param {Number} obj.curriculumId 课程id
 * @param {Number} obj.feedback 内容
 * @returns {Promise}
 */
export function setStudentCurriculumFeedback({
  studentId,
  curriculumId,
  feedback,
}) {
  return http({
    url: '/master/curriculum/setStudentCurriculumFeedback',
    method: 'POST',
    data: {
      studentId,
      curriculumId,
      feedback: {
        'feedback': feedback,
      },
    },
  });
}

/**
 * 添加作业目录
 * @param {Object} obj 传入值
 * @param {Number} obj.curriculumId 课程id
 * @param {Number} obj.directoryName 标题名称
 * @param {Number} obj.content 备注_描述
 * @returns {Promise}
 */
export function addCurriculumDirectory({
  curriculumId,
  directoryName,
  content,
}) {
  return http({
    url: '/master/curriculum/addCurriculumDirectory',
    method: 'POST',
    data: {
      curriculumId,
      directoryName,
      content,
    },
  });
}

/**
 * 修改目录信息
 * @param {Object} obj 传入值
 * @param {Number} obj.directoryId 目录id
 * @param {Number} obj.curriculumId 课程id
 * @param {String} obj.directoryName 标题名称
 * @param {String} obj.content 备注_描述
 * @returns {Promise}
 */
export function updateCurriculumDirectory({
  directoryId,
  curriculumId,
  directoryName,
  content,
}) {
  return http({
    url: '/master/curriculum/updateCurriculumDirectory',
    method: 'POST',
    data: {
      directoryId,
      curriculumId,
      directoryName,
      content,
    },
  });
}

/**
 * 删除作业目录
 * @param {Object} obj 传入值
 * @param {Number} obj.directoryId 目录id
 * @param {Number} obj.curriculumId 课程id
 * @returns {Promise}
 */
export function deleteDirectory({
  directoryId,
  curriculumId,
}) {
  return http({
    url: '/master/curriculum/deleteDirectory',
    method: 'POST',
    data: {
      directoryId,
      curriculumId,
    },
  });
}

/**
 * 确认上传作业附件 ??
 * @param {Object} obj 传入值
 * @param {Number} obj.directoryId 目录id
 * @param {Number} obj.curriculumId 课程id
 * @param {Array<String>} obj.fileNums 关联文件id
 * @returns {Promise}
 */
export function uploadCurriculumDirectory({
  directoryId,
  curriculumId,
  fileIds,
}) {
  return http({
    url: '/master/curriculum/uploadCurriculumDirectory',
    method: 'POST',
    data: {
      directoryId,
      curriculumId,
      fileNums: fileIds,
    },
  });
}

/**
 * 确认上传作业附件 ??
 * @param {Object} obj 传入值
 * @param {Number} obj.directoryId 目录id
 * @param {Number} obj.curriculumId 课程id
 * @param {Array<String>} obj.fileNums 关联文件id
 * @returns {Promise}
 */
export function deleteCurriculumDirectoryData({
  directoryId,
  curriculumId,
  fileNums,
}) {
  return http({
    url: '/master/curriculum/deleteCurriculumDirectoryData',
    method: 'POST',
    data: {
      directoryId,
      curriculumId,
      fileNums,
    },
  });
}

/**
 * 确认上传课程资料
 * @param {Object} obj 传入值
 * @param {Number} obj.curriculumId 课程id
 * @param {Array<Number>} obj.fileIds 关联文件id
 * @returns {Promise}
 */
export function uploadCurriculumData({
  curriculumId,
  fileIds,
}) {
  return http({
    url: '/master/curriculum/uploadCurriculumData',
    method: 'POST',
    data: {
      curriculumId,
      'fileNums': fileIds,
    },
  });
}

/**
 * 删除 课程资料文件
 * @param {Object} obj 传入值
 * @param {Number} obj.curriculumId 课程id
 * @param {Array<Number>} obj.fileIds 关联文件id
 * @returns {Promise}
 */
export function deleteCurriculumData({
  curriculumId,
  fileIds,
}) {
  return http({
    url: '/master/curriculum/deleteCurriculumData',
    method: 'POST',
    data: {
      'curriculumId': curriculumId,
      'fileNums': fileIds,
    },
  });
}
