import { Message as message } from 'element-ui';
import Vue from 'vue';
import request from './axios.config';
/**
 * @function
 * @param {Objec} object
 * @param {String} object.url - 路径啊
 * @param {Map<String,any>} object.data - get的时候是查询参数 其它就是body 其它有查询参数? 直接写在路径上不想管
 * @param {String} object.method - 请求方法
 * @param {Map<String,any>} object.headers - 请求头
 * @param {Function} object.beforeRequest - 请求前不影响请求返回的
 * @param {Function} object.afterRequest -请求接收后f后不影响请求返回的
 * @param {Function} object.errorFn - 错误替换 记得抛出错误
 * @returns {Promise}
 */
const http = function({ url, data, method = 'GET', headers, beforeRequest, afterRequest, errorFn, }) {
  const successHandler = async function(res) {
    console.log(url, data, res.headers, res.result);
    afterRequest && afterRequest();
    if (res instanceof Blob && res.type === 'application/json') {
      res = JSON.parse(await res.text());
    }
    if (res instanceof Blob && res.type !== 'application/json') {
      return res;
    }
    if (res.code === 200) {
      return res;
    }
    message({
      message: res.msg || res.message || '请求失败，未知异常',
      type: 'error',
    });
    throw new Error(res.msg || res.message || '  ');
  };

  const failHandler = errorFn ?? (error => {
    afterRequest && afterRequest();
    message({
      message: error.msg || error.message || '请求失败，未知异常',
      type: 'error',
    });
    console.log('请求失败,未知异常', url, data, error);
    throw new Error(error.msg || error.message || '请求失败，未知异常');
  });
  beforeRequest && beforeRequest();
  switch (method) {
    case 'GET':
    case 'get':
      return request.get(url, { params: data, ...headers, }).then(successHandler, failHandler);
    case 'POST':
    case 'post':
      return request.post(url, data, { headers, }).then(successHandler, failHandler);
    case 'DELETE':
    case 'delete':
      return request.delete(url, { data, ...headers, }).then(successHandler, failHandler);
    case 'PUT':
    case 'put':
      return request.put(url, data, { headers, }).then(successHandler, failHandler);
    default:
      break;
  }
};
Vue.prototype.$http = http;
// get方法
function get({ url, data, beforeRequest = null, afterRequest = null, }) {
  return this.$http({ url, method: 'GET', data, beforeRequest, afterRequest, });
}
Vue.prototype.$get = get;

function post({ url, data, headers, beforeRequest = null, afterRequest = null, }) {
  console.log(url, data, headers, beforeRequest, afterRequest);
  return this.$http({ url, method: 'POST', data, headers, beforeRequest, afterRequest, });
}
Vue.prototype.$post = post;

export { http };

