import { userApi } from '@/utils/api';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: '',
  },
  getters: {
    getName(state) {
      return state.name ?? '';
    },
  },
  mutations: {
    SETNAME(state, name) {
      state.name = name;
    },
  },
  actions: {
    setName({ commit, }, name) {
      commit('SETNAME', name);
    },
    async getNameOfApi({ commit, }) {
      var res = await userApi.getUserInfo();
      console.log('我死了', res);
      commit('SETNAME', res.result.userName);
    },
  },
  modules: {},
});
