import { http } from '@/utils/api/http.js';
import Axios from 'axios';

/** 删除上传但未保存接口
 * @param {Array<String>} fileList
 */
export function removeFile(fileList) {
  return http({
    url: '/master/remove-file',
    method: 'POST',
    data: fileList,
  });
}
/** 查看上传但未保存接口
 * @param {Object} obj
 * @param {int} obj.status 状态:0保存中的、1通过审核、2审核中的(正常确认上传后的状态)、3未通过审核的/null查全部
 */
export function getSaveFile({
  curriculumId,
  directoryId,
  status,
}) {
  return http({
    url: '/master/get/saveFile',
    method: 'GET',
    data: {
      curriculumId,
      directoryId, status,
    },
  });
}

/**
 * 上传文件 头像、图片，（教学资料与作业不在其中)
 * @param {FormData} fromData 上传文件数据 {file}
 * @returns {Promise}
 */
export function uploadFile(fromData, purpose) {
  return http({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/master/upload-file?purpose=' + purpose,
    method: 'POST',
    data: fromData,
  });
}

/**
 * 读取文件 图片头像等、教学资料与作业读取非该接口
 * @param {FormData} picName 文件名称
 * @returns {Promise}
 */
export function readFile(picName) {
  return http({
    url: '/master/read-file/' + picName,
    method: 'GET',
  });
}
/**
 *上传保存 课程资料（返回文件id(数组)
 * @param {FormData} fromData {file[0],cId,directoryId} 数据,课程id,作业目录
 * @returns {Promise}
 */
export function uploadFileHomework(fromData) {
  return http({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/master/upload-file-homework',
    method: 'POST',
    data: fromData,
  });
}
/**
 *上传保存 课程资料（返回文件id(数组)
 * @param {FormData} fromData {file[0],cId,} 数据,课程id
 * @returns {Promise}
 */
export function uploadFileCurriculum(fromData) {
  return http({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: '/master/upload-file-curriculum',
    method: 'POST',
    data: fromData,
  });
}
/**
 * 教师上传保存 作业附件(返回文件id数组)
 * @param {FormData} fromData {file[0],cId,} 数据,课程id
 * @returns {Promise}
 */
export function uploadFileCurriculum2(fromData) {
  const abc = Axios.create({
    baseURL: '127.0.0.1:3000',
    timeout: 10 * 60 * 1000,
    header: {
      'Content-Type': 'multipart/form-data',
    },
  });
  abc.interceptors.request.use(
    config => {
      console.log(555, config);
      config.baseURL = '127.0.0.1:3000';
      config.headers['Content-Type'] = 'multipart/form-data';
      return config;
    },
    error => {
      return Promise.reject(error.response);
    }
  );
  console.log(abc, 22222);
  return abc.post(
    'http://127.0.0.1:3000/abc2',
    fromData,
    { headers: {
      'Content-Type': 'multipart/form-data',
    }, }
  );
}

/**
 * 教师端 读取作业、教学资料
 * @param {FormData} dataId 文件id
 * @returns {Promise}
 */
export function readFileHomeworkData(dataId) {
  return http({

    url: '/master/read-file-homework-data/' + dataId,
    method: 'POST',
    data: dataId,
  });
}

/**
 * 编辑 资料或作业附件 名称
 * @param {FormData} dataId 文件id
 * @returns {Promise}
 */
export function setFileName({ fileId, fileName, }) {
  return http({
    url: '/master/setFileName',
    method: 'POST',
    data: {
      fileNum: fileId, fileName,
    },
  });
}
/** 下载文件啊 */
export function downfile(path) {
  return http({
    headers: {
      'Content-Type': 'multipart/form-data',
      'responseType': 'blob',
    },
    url: path,
    method: 'GET',
  });
}
