import { http } from '@/utils/api/http.js';

/**
 * 邮箱和密码登录
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.loginPassword - 登录密码
 * @returns {Promise<String,any}
 */
export function loginWithPhoneAndPwd({
  userEmail,
  loginPassword,
}) {
  return http({
    url: '/master/auth/loginWithPhoneAndPwd',
    method: 'POST',
    data: {
      userEmail,
      loginPassword,
    },
  });
}

/**
 * 邮箱和验证码登录
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.code - 登录密码
 * @returns {Map<String,any}
 */
export function loginWithPhoneAndCode({
  userEmail,
  code,
}) {
  return http({
    url: '/master/auth/loginWithPhoneAndCode',
    method: 'POST',
    data: {
      userEmail,
      code,
    },
  });
}

/**
 * 获取邮箱验证码
 * @param {String} email - 邮箱
 * @returns {Promise<Map<String,any>>}
 */
export function getEmailCode(
  { email, }
) {
  return http({
    url: '/master/auth/getEmailCode/' + email,
    method: 'GET',
  });
}

/**
 * 教师注册
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.loginPassword - 登录密码
 * @param {String} obj.code - 验证码
 * @param {String} obj.userName - 名称
 * @returns {Map<String,any}
 */
export function register({
  userEmail,
  loginPassword,
  code,
  userName,
}) {
  return http({
    url: '/master/auth/register',
    method: 'POST',
    data: {
      userEmail,
      loginPassword,
      code,
      userName,
    },
  });
}

/**
 * 重置密码
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.newLoginPassword - 新登录密码
 * @param {String} obj.code - 验证码
 * @returns {Map<String,any}
 */
export function resetPassword({
  userEmail,
  newLoginPassword,
  code,
}) {
  return http({
    url: '/master/auth/resetPassword',
    method: 'POST',
    data: {
      userEmail,
      newLoginPassword,
      code,
    },
  });
}

/**
 * 验证码验证
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.code - 验证码
 * @returns {Map<String,any}
 */
export function resetPasswordCode({
  userEmail,
  code,
}) {
  return http({
    url: '/master/auth/resetPasswordCode',
    method: 'POST',
    data: {
      userEmail,
      code,
    },
  });
}

/**
 * 修改密码
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.loginPassword - 登录密码
 * @param {String} obj.newLoginPassword - 新登录密码
 * @param {String} obj.code - 验证码
 * @returns {Map<String,any}
 */
export function updatePassword({
  userEmail,
  loginPassword,
  newLoginPassword,
  code,
}) {
  return http({
    url: '/master/auth/updatePassword',
    method: 'POST',
    data: {
      userEmail,
      loginPassword,
      newLoginPassword,
      code,
    },
  });
}

/**
 * 修改密码
 * @param {Object} obj
 * @param {String} obj.userEmail - 邮箱
 * @param {String} obj.newUserEmail - 邮箱
 * @param {String} obj.code - 登录密码
 * @param {String} obj.userPhone - 手机
 * @param {String} obj.userAvatar - 头像
 * @param {String} obj.code - 验证码
 * @returns {Map<String,any}
 */
export function updateUserInfo({
  userEmail,
  newUserEmail,
  userPhone,
  userName,
  userAvatar,
  code,
}) {
  return http({
    url: '/master/auth/updateUserInfo',
    method: 'POST',
    data: {
      userEmail,
      newUserEmail,
      userPhone,
      userName,
      userAvatar,
      code,
    },
  });
}

export function getUserInfo() {
  return http({
    url: '/master/auth/getUserInfo',
    method: 'GET',
  });
}
