import { AskPage } from '@/classLib/pageClass';
AskPage;

import { http } from '@/utils/api/http.js';

/**
 * 教师获取请假审核列表
 * @param {AskPage} pageModel 页面请求数据
 * @returns {Promise}
 */
export function getMasterAuditAsk(pageModel) {
  return http({
    url: '/master/ask/getMasterAuditAsk',
    method: 'GET',
    data: pageModel.toSpecialMap(),
  });
}

/**
 * 删除学生请假操作记录
 * @param {Array<int>} data 学生请假记录id
 * @returns {Promise}
 */
export function deleteMasterAuditAsk(data) {
  return http({
    url: '/master/ask/deleteMasterAuditAsk',
    method: 'POST',
    data,
  });
}

/**
 * 审批学生请假操作
 * @param {Array<int>} askIds 要审核的记录id
 * @param {int} status 审批结果：1同意、2拒绝
 * @returns {Promise}
 */
export function auditAsk(askIds, status) {
  return http({
    url: '/master/ask/auditAsk',
    method: 'POST',
    data: {
      askIds,
      status,
    },
  });
}

/**
 * 审批取消课程操作
 * @param {int} cancelCurriculumId 取消课程记录id
 * @param {int} status 审批结果：1同意、2拒绝
 * @returns {Promise}
 */
export function approvalCancelCurriculum(cancelCurriculumId, status) {
  return http({
    url: '/master/ask/approvalCancelCurriculum',
    method: 'POST',
    data: {
      cancelCurriculumId,
      status,
    },
  });
}
