<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AppView',

};
</script>

<style>
:root{
  font-size: calc(1200 / 1920 * 10px);
}
body,html{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
@media screen and ( min-width: 1200px){
  :root{
    font-size:calc(100vw / 1920 * 10)
  }

}
a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

.img-box>img{
  display: block;
  width: 100%;
}
.el-popover{
  padding: 0;
  border-radius: 10px;
}
</style>
