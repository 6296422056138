/**
 * @export
 * @class PageParameter
 * @callback 主要功能是为了保证请求参数在合理范围,不进行请求
 */
export class PageParameter {
  /**
   * Creates an instance of PageParameter.
   * @param {Object} object { pageNo = 1, pageSize = 10, pageTotal = 10, }
   * @param {number} object.pageNo  - 页码
   * @param {number} object.pageSize  - 单页大小
   * @param {number} object.pageTotal  - 数据总量
   * @param {number} object.timeType  - 按创建时间排序 0倒序，1升序
   * @param {number} object.startTime  - 条件 开始时间
   * @param {number} object.endTime  - 条件 结束时间
   * @memberof PageParameter
   */
  constructor({ pageNo = 1, pageSize = 10, pageTotal = 10, timeType = 0, startTime
    , endTime, }) {
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.pageTotal = pageTotal;
    this.timeType = timeType ?? 0;
    this.searchWord = null;
    this.startTime = startTime ?? null;
    this.endTime = endTime ?? null;
    Object.defineProperty(this, 'specialfield', {
      value: [ 'pageNo', 'pageSize', 'pageTotal', 'timeType', 'searchWord', 'startTime', 'endTime', ],
      enumerable: false,
    });
  }
  /** @type {Number} - 页码 */
  pageNo;
  /** @type {Number} - 单页大小 */
  pageSize;
  /** @type {Number} - 数据总数 */
  pageTotal;
  /** @type {Number} - 按创建时间排序 0倒序，1升序 */
  timeType;
  /** @type {String} - 要求寻找的数据保护搜索词 */
  searchWord;
  /** @type {Number} - 要求寻找的数据创建时间大于这个时间  单位秒 */
  startTime;
  /** @type {Number} - 要求寻找的数据创建时间大于这个时间  单位秒 */
  endTime;
  /** @type {Array} get请求时候的特殊字段 */
  specialfield;
  /** 判断是否可以下一页 */
  get isCanNext() {
    if (this.pageNo >= this.getMaxPageNo) {
      return false;
    }
    return true;
  }
  /** 判断是否可以上一页 */
  get isCanPer() {
    if (this.pageNo === 1) {
      return false;
    }
    return true;
  }
  /** 获取当前最大页码 */
  get getMaxPageNo() {
    return Math.ceil(this.pageTotal / this.pageSize);
  }
  /**
   * @param {*} pageNo
   * @memberof PageParameter
   */
  jumpPage(_pageNo) {
    if (_pageNo >= this.getMaxPageNo) {
      this.pageNo = this.getMaxPageNo;
      return;
    }
    if (_pageNo <= 0) {
      this.pageNo = 1;
      return;
    }
    this.pageNo = _pageNo;
  }
  toMap() {
    const map = {};
    Object.keys(this).forEach(element => {
      map[element] = this[element];
    });
    return map;
  }
  toSpecialMap() {
    const map = {};
    Object.keys(this).forEach(element => {
      if (this.specialfield.includes(element)) {
        map['pageParameter.' + element] = this[element];
      } else {
        map[element] = this[element];
      }
    });
    return map;
  }
}
/**
 * @export
 * @class TeacherLessonPage
 * @classdesc 教师请求课程的页面模型
 * @extends {PageParameter}
 */
export class TeacherLessonPage extends PageParameter {
  /** @type {Number} - 类型一对一（1）或一对多（0） */
  type;
  /** @type {Number} - 课程开始时间 */
  startTime;
  /** @type {Number} - 课程开始时间 */
  endTime;
  /** @type {Number} - 现在进行中的课程 */
  forNowTime;
  /** @type {Number} - 删除状态_1删除_0正常 */
  isDel;
  /** @type {Number} - 当前课程状态_0待完成_1完成_2取消 */
  curriculumStatus;
  /**
   * Creates an instance of TeacherLessonPage.
   * @param {Object} object { pageNo, pageSize, pageTotal, type, startTime, endTime, forNowTime, isDel, curriculumStatus, }
   * @param {number} object.pageNo  - 页码
   * @param {number} object.pageSize  - 单页大小
   * @param {number} object.pageTotal  - 数据总量
   * @param {Number} object.type - 类型一对一（1）或一对多（0）
   * @param {Number} object.startTime - 课程开始时间
   * @param {Number} object.endTime - 课程开始时间
   * @param {Number} object.forNowTime - 现在进行中的课程
   * @param {Number} object.isDel - 删除状态_1删除_0正常
   * @param {Number} object.curriculumStatus - 当前课程状态_0待完成_1完成_2取消
   * @memberof TeacherLessonPage
   */
  constructor({ pageNo, pageSize, pageTotal, type, startTime, endTime, forNowTime, isDel, curriculumStatus, }) {
    super({ pageNo, pageSize, pageTotal, });
    this.type = type ?? null;
    this.startTime = startTime ?? null;
    this.endTime = endTime ?? null;
    this.forNowTime = forNowTime ?? null;
    this.isDel = isDel ?? null;
    this.curriculumStatus = curriculumStatus ?? null;
  }
  /** 当前课程状态_0待完成_1完成_2取消_null */
  changeCurriculumStatus(status) {
    console.log(status);
    if (![ 0, 1, 2, null, ].includes(status)) {
      throw new Error('未知课程状态');
    }
    this.curriculumStatus = status;
  }
}

export class MassagePage extends PageParameter {
  /**
   * Creates an instance of MassagePage.
   * @param {Object} object { pageNo, pageSize, pageTotal, type, startTime, endTime, forNowTime, isDel, curriculumStatus, }
   * @param {number} object.pageNo  - 页码
   * @param {number} object.pageSize  - 单页大小
   * @param {number} object.pageTotal  - 数据总量
   * @param {number} object.timeType  - 按创建时间排序 0倒序，1升序
   * @param {number} object.chatRoomId  - 消息房间号
   * @memberof MassagePage
   */
  constructor({ pageNo, pageSize, pageTotal, timeType, chatRoomId, startTime, endTime, }) {
    super({ pageNo, pageSize, pageTotal, timeType, startTime, endTime, });
    this.chatRoomId = chatRoomId;
  }
  /** @type {number}  - 消息房间号 */
  chatRoomId;
}

/**
 * @export
 * @class TeacherLessonPage
 * @classdesc 教师请求请假页面模型
 * @extends {PageParameter}
 */
export class AskPage extends PageParameter {
  /**
   * @param {Object} object { pageNo = 1, pageSize = 10, pageTotal = 10, }
   * @param {number} object.pageNo  - 页码
   * @param {number} object.pageSize  - 单页大小
   * @param {number} object.pageTotal  - 数据总量
   * @param {number} object.timeType  - 按创建时间排序 0倒序，1升序
   * @param {number} object.startTime  - 条件 开始时间
   * @param {number} object.endTime  - 条件 结束时间
   * @param {number} object.masterId  - 老师id
   * @param {number} object.status  - 审核状态 0待审核、1同意、2拒绝、3撤销、其他值或不传为 全部
   */
  constructor({
    pageNo,
    pageSize,
    pageTotal,
    timeType,
    startTime,
    endTime,
    masterId,
    status,
  }) {
    super({ pageNo, pageSize, pageTotal, timeType, startTime, endTime, });
    this.masterId = masterId;
    this.status = status;
  }
  /** @type {number} 老师id */
  masterId;
  /** @type {number}  审核状态 0待审核、1同意、2拒绝、3撤销、其他值或不传为 全部 */
  status;
  changeAskStatus(status) {
    if (![ 0, 1, 2, 3, null, ].includes(status)) {
      throw new Error('未知状态');
    }
    this.status = status;
  }
}
