import { http } from '@/utils/api/http.js';

/**
 * 教师获取消息列表数据
 * @param {MassagePage} pageModel 页面请求数据
 * @returns {Promise}
 */
export function getPageRoomMessages(pageModel) {
  return http({
    url: '/master/messages/getPageRoomMessages',
    method: 'GET',
    data: pageModel.toSpecialMap(),
  });
}

/**
 * 发送信息
 * @param {Object} object 传入值
 * @param {number} object.chatRoomId 房间id
 * @param {String} object.message 消息
 * @param {number} object.messageType 消息类型
 * @returns {Promise}
 */
export function sendMessage({
  chatRoomId,
  message,
  messageType,
}) {
  return http({
    url: '/master/messages/sendMessage',
    method: 'POST',
    data: {
      chatRoomId,
      message,
      messageType,
    },
  });
}

/**
 * 教师 获取聊天室最新消息time
 * @param {Object} object 传入值
 * @param {number} object.cId 房间id
 * @returns {Promise}
 */
export function getNewRoomMessageTime({
  cId,
}) {
  return http({
    url: '/master/messages/getNewRoomMessageTime/' + cId,
    method: 'GET',
  });
}

/**
 * 教师 撤回消息（5分钟内）
 * @param {Object} object 传入值
 * @param {number} object.mId 消息id
 * @returns {Promise}
 */
export function withdrawMessage({
  mId,
}) {
  return http({
    url: '/master/messages/withdrawMessage/' + mId,
    method: 'POST',
  });
}

/**
 * 教师 获取聊天室/聊天列表
 * @param {Object} object 传入值
 * @param {PageParameter} object.pageParameter 消息id
 * @returns {Promise}
 */
export function getChatRoomPage({
  pageParameter,
}) {
  return http({
    url: '/master/messages/getChatRoomPage',
    method: 'GET',
    data: pageParameter.toSpecialMap(),
  });
}

/**
 * 教师 建立聊天关系、
 * @param {Object} object 传入值
 * @param {String} object.roomRemarkName 聊天室备注
 * @param {Number} object.pmUserId 私聊下目标用户id
 * @param {Number} object.type 目标用户类型：1学生、2老师
 * @returns {Promise} 返回聊天室id(获取与该用户的聊天室id)
 */
export function addChatRoomRelation({
  roomRemarkName,
  pmUserId,
  type,
}) {
  return http({
    url: '/master/messages/addChatRoomRelation',
    method: 'POST',
    data: {
      roomRemarkName,
      pmUserId,
      type,
    },
  });
}

/**
 * 教师 隐藏、关闭聊天室 接收消息后恢复
 * @param {Object} object 传入值
 * @param {Number} object.roomId 聊天室id
 * @returns {Promise}
 */
export function removeChatRoomSolo({
  roomId,
}) {
  return http({
    url: '/master/messages/removeChatRoomSolo/' + roomId,
    method: 'POST',
  });
}

/**
 * 教师 拉黑、退出  聊天室(群聊为退出、私聊为拉黑)
 * @param {Object} object 传入值
 * @param {Number} object.roomId 聊天室id
 * @returns {Promise}
 */
export function shieldingChatRoomSolo({
  roomId,
}) {
  return http({
    url: '/master/messages/shieldingChatRoomSolo/' + roomId,
    method: 'POST',
  });
}

/**
 * 教师 恢复 隐藏、关闭的聊天室(私聊)(返回聊天室id)返回null时代表不存在或已拉黑
 * @param {Object} object 传入值
 * @param {Number} object.pmUserId 目标用户id
 * @param {Number} object.type 目标用户类型：0学生、1老师
 * @returns {Promise}
 */
export function recoverChatRoomSolo({
  pmUserId,
  type,
}) {
  return http({
    url: `/master/messages/recoverChatRoomSolo/${pmUserId}/${type}`,
    method: 'POST',
  });
}

/**
 * 教师 恢复 私聊拉黑的聊天室
 * @param {Object} object 传入值
 * @param {Number} object.roomId 聊天室id
 * @returns {Promise}
 */
export function shieldingRecoverChatRoomSolo({
  roomId,
}) {
  return http({
    url: '/master/messages/shieldingRecoverChatRoomSolo/' + roomId,
    method: 'POST',
  });
}

/**
 * 教师 恢复 隐藏、关闭的聊天室(私聊)(返回聊天室id)返回null时代表不存在或已拉黑
 * @param {Object} object 传入值
 * @param {Number} object.roomId 聊天室id
 * @param {Number} object.type 设置：0正常、1勿扰
 * @returns {Promise}
 */
export function setRoomDisturb({
  chatRoomId,
  roomType,
}) {
  return http({
    url: `/master/messages/setRoomDisturb/${chatRoomId}/${roomType}`,
    method: 'POST',
  });
}

/**
 * 教师 设置聊天室备注名称
 * @param {Object} object 传入值
 * @param {Number} object.roomId 聊天室id
 * @param {Number} object.remarkName 备注名称
 * @returns {Promise}
 */
export function setRoomRemarkName({
  roomId,
  remarkName,
}) {
  return http({
    url: `/master/messages/setRoomRemarkName/${roomId}/${remarkName}`,
    method: 'POST',
  });
}

