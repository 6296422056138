import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
// 这里捕获重复跳转错误让他不显示
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  console.log(location);
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); }
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    redirect: '/lesson/list/Alllessons',
    name: 'root',
    component: () => import('@/layouts'),
    children: [
      {
        path: '/lesson',
        name: 'lesson',
        redirect: '/lesson/list/Alllessons',
        component: () => import('@/views/lessons'),
        children: [
          {
            path: 'list/:type',
            name: 'lessonList',
            component: () => import('@/views/lessons/lessons-list'),
          },
          {
            path: 'calendar',
            name: 'lessonCalendar',
            component: () => import('@/views/lessons/lessons-calendar.vue'),
          },
          {
            path: 'detailed/:cId',
            name: 'lessonDetailed',
            component: () => import('@/views/lessons/lessons-detailed.vue'),
          },
        ],
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('@/views/message'),
        children: [
        ],
      },
      {
        path: '/ask',
        redirect: '/ask/asklist',
        name: 'ask',
        component: () => import('@/views/ask'),
        children: [
          {
            path: 'asklist',
            name: 'asklist',
            component: () => import('@/views/ask/ask-page'),
          },

        ],
      },
      {
        path: '/userinfo',
        name: 'userinfo',
        redirect: '/userinfo/modifyinfo',
        component: () => import('@/views/useInfo'),
        children: [
          {
            path: 'modifyinfo',
            name: 'modifyinfo',
            component: () => import('@/views/useInfo/modify-info'),
          },
          {
            path: 'modifyaccount',
            name: 'modifyaccount',
            component: () => import('@/views/useInfo/modify-account'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/lesson/list/Alllessons',
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import('../views/AboutView.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () =>
      import('@/views/user'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/user/login-view'),
      },
      {
        path: 'createaccount',
        name: 'createaccount',
        component: () => import('@/views/user/create-account'),
      },
      {
        path: 'forgetcode',
        name: 'forgetcode',
        component: () => import('@/views/user/forget-code'),
      },
      {
        path: 'forgetemail',
        name: 'forgetemail',
        component: () => import('@/views/user/forget-email'),
      },
      {
        path: 'forgetpassword',
        name: 'forgetpassword',
        component: () => import('@/views/user/forget-password'),
      },
    ],
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
