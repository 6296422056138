import router from '@/router';
import store from '@/store';
import Cookies from 'js-cookie';

/**  获取会话cookies[admin-token] ture:有 false:无 */
function isTokenExpired() {
  const token = Cookies.get('teacher-token');
  return !!token;
}
const whiteList = [ 'login', 'createaccount', 'forgetcode', 'forgetemail', 'forgetpassword', ];
router.beforeEach((to, from, next) => {
  // 在白名单内就跳过验证
  console.log(whiteList.includes(to.name), to.name);
  // next();
  // isTokenExpired;
  // whiteList;
  if (whiteList.includes(to.name)) {
    next();
  } else {
    if (!isTokenExpired()) {
      // 没有token直接跳到登录页
      next('/user/login');
    } else {
      console.log('abc');
      if (store.getters.getName === '') {
        store.dispatch('getNameOfApi');
      }
      // 有token就不管
      next();
    }
  }
});
