import '@/utils/router';
import {
  Button, ButtonGroup,
  Calendar, Card, Checkbox, CheckboxGroup, Collapse,
  CollapseItem, DatePicker, Dialog, Divider, Drawer, Empty, Header, Input, Message, Popover, Radio, Select, Step, Steps, Switch, Table,
  TableColumn, TabPane, Tabs, Tooltip, Upload
} from 'element-ui';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment';
import Vue from 'vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
// import ElementUI from 'element-ui'

locale.use(lang);
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Header.name, Header);
Vue.component(Card.name, Card);
Vue.component(Input.name, Input);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Popover.name, Popover);
Vue.component(Divider.name, Divider);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Calendar.name, Calendar);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Dialog.name, Dialog);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Radio.name, Radio);
Vue.component(Switch.name, Switch);
Vue.component(Drawer.name, Drawer);
Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.component(Upload.name, Upload);
Vue.component(Empty.name, Empty);
Vue.prototype.$message = Message;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
